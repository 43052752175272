<template>
  <div class="dashboard">
    <div class="row row-equal">
      <div class="flex xs12 lg6">
      </div>
      <div class="flex xs12 lg6">
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'dashboard',
  components: {
  },
  methods: {

  },
}
</script>

<style lang="scss">
  .row-equal .flex {
    .va-card {
      height: 100%;
    }
  }

  .dashboard {
    .va-card {
      margin-bottom: 0 !important;
      &__title {
        display: flex;
        justify-content: space-between;
      }
    }
  }
</style>
